












































import { Observer } from 'mobx-vue'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { createPostController } from '@/modules/common/dialogs/create-post/create-post-controller'

@Observer
@Component({
  components: {
    'noti-connect-wallet-dialog': () => import('@/modules/community/home/dialog/noti-connect-wallet.vue'),
  },
})
export default class community extends Vue {
  createPostController = createPostController

  hideFloatingButton = false

  @Watch('$vuetify.breakpoint.width', { immediate: true }) onHideFloatingButton(width: number) {
    if (width >= 737) {
      this.hideFloatingButton = true
      this.isOverlay = false
      return
    }
    this.hideFloatingButton = false
  }

  isOverlay = false

  overlay() {
    this.isOverlay = !this.isOverlay
  }

  onPostNow() {
    this.isOverlay = false
    createPostController.show(true)
  }

  onCreateDao() {
    this.isOverlay = false
    this.redirectCreateDao()
  }

  redirectCreateDao = () => {
    this.$router.push('/community-organize/dao/create')
  }

  get hide(): boolean {
    return this.$route.name === 'Trending-No-Auth' || this.$vuetify.breakpoint.width < 1280
  }
}
